.App {
  text-align: center;
}
.App-header {
  background-color: #f5f5f5;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  position: fixed;
  bottom: 0px;
  text-align: center;
  background-color: #eacfcf;
  width: 100%;
}

.App-footer-text {
 color: red;
 font-size: 1.3em;
}

